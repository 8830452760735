<template>
<v-dialog v-model="open" max-width="400px">
  <template v-slot:activator="{ on, attrs }">
    <v-btn ref="activator" v-bind="attrs" v-on="on" text>Add paid role</v-btn>
  </template>
  <v-card>
    <v-card-title>
      Add paid role
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row dense>
          <v-col cols="8">
            <v-text-field v-model="name" label="Additional role name" autocomplete="off"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="rate" label="Rate (/hr)"
                          prefix="£" type="number">
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="open = false">Cancel</v-btn>
      <v-btn text :disabled="!valid" @click="add">Add</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import * as api from '../../api'

export default {
  name: 'AddPaidRole',
  props: ['userId'],
  data() {
    return {
      open: false,
      name: null,
      rate: 0
    }
  },
  watch: {
    open() {
      this.name = null
      this.rate = 0
    }
  },
  computed: {
    valid() {
      return this.name && this.rate > 0
    }
  },
  methods: {
    async add() {
      const { userId, name, rate } = this
      await api.post('/paid-role', { user_id: userId, name, rate })
      this.open = false
      this.$emit('add')
      return this.$store.dispatch('getUsers', true)
    }
  }
}
</script>

<style scoped>
button:focus:before {
  opacity: 0 !important;
}
</style>
