<template>
<v-dialog v-model="dialog" width="unset">
  <template v-slot:activator="{}">
    <user-working-icon :working="user && user.working"
                       @click="dialog = !dialog">
    </user-working-icon>
  </template>
  <v-card>
    <v-date-picker v-model="date" first-day-of-week="1"
                   :events="eventColour">
    </v-date-picker>
    <div class="d-flex">
      <v-btn text @click="dialog = false">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn text @click="setWorking(false)">Off</v-btn>
      <v-btn text @click="setWorking(true)">On</v-btn>
    </div>
  </v-card>
</v-dialog>
</template>

<script>
import * as api from '../../api'
import UserWorkingIcon from './UserWorkingIcon'
import { workingColours } from '../../data'
import { today } from '../../util'

export default {
  name: 'UserWorking',
  components: { UserWorkingIcon },
  props: {
    user: Object
  },
  data() {
    return {
      dialog: false,
      date: today(),
      workingDates: {}
    }
  },
  watch: {
    dialog() {
      if (this.dialog) {
        api.get('/user/working/dates', { user_id: this.user.id }).then((dates) => {
          for (const { start_date, working } of dates) {
            this.$set(this.workingDates, start_date, working)
          }
        })
      }
    }
  },
  computed: {
  },
  methods: {
    eventColour(date) {
      if (date in this.workingDates) {
        return this.workingDates[date] ? workingColours.working : workingColours.off
      }
      return null
    },
    async setWorking(value) {
      await api.post('/user/working', {
        user_id: this.user.id,
        working: value,
        start_date: this.date
      })
      this.dialog = false
      this.$emit('update')
    }
  }
}
</script>

<style scoped lang="scss">

</style>
