var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-wrapper',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.userList,"disable-pagination":"","hide-default-footer":"","loading":_vm.users.length === 0},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v("Users")]),_c('v-spacer'),_c('div',[_c('v-checkbox',{staticStyle:{"margin-top":"20px"},attrs:{"label":"Only active"},model:{value:(_vm.onlyActive),callback:function ($$v) {_vm.onlyActive=$$v},expression:"onlyActive"}})],1),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"680px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":_vm.resetEditor}},'v-btn',attrs,false),on),[_vm._v("Add new")])]}}]),model:{value:(_vm.editing),callback:function ($$v) {_vm.editing=$$v},expression:"editing"}},[_c('edit-user',{ref:"editor",on:{"close":function($event){_vm.editing = false}}})],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none black--text hover-underline",attrs:{"to":{ name: 'user-calendar', params: { userSlug: item.slug } }}},[_c('span',{class:{ 'grey--text': !item.active }},[_vm._v(" "+_vm._s(item.name)+" ")])]),(item.admin)?_c('v-chip',{staticClass:"ml-3",attrs:{"label":"","small":""}},[_vm._v("Admin")]):_vm._e()]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'grey--text': !item.active }},[_vm._v(" "+_vm._s(_vm._f("fullTitle")(item.role))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'grey--text': !item.active }},[_vm._v(" "+_vm._s(_vm._f("title")(item.status))+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'grey--text': !item.active }},[(item.email)?_c('span',[_vm._v(_vm._s(item.email))]):_c('i',{staticClass:"grey--text"},[_vm._v("Not set")])])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-4",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.working",fn:function(ref){
var item = ref.item;
return [_c('user-working',{attrs:{"user":item},on:{"update":_vm.update}})]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-4",attrs:{"small":""},on:{"click":function($event){return _vm.$router.push({ name: 'user-calendar', params: { userSlug: item.slug } })}}},[_vm._v(" mdi-calendar ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }