<template>
<page-wrapper>
  <v-data-table :headers="headers" :items="userList"
                disable-pagination hide-default-footer
                :loading="users.length === 0">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title class="title">Users</v-toolbar-title>
        <v-spacer></v-spacer>
        <div>
          <v-checkbox v-model="onlyActive" label="Only active"
                      style="margin-top: 20px">
          </v-checkbox>
        </div>
        <v-spacer></v-spacer>
        <v-dialog v-model="editing" max-width="680px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" @click="resetEditor">Add new</v-btn>
          </template>
          <edit-user ref="editor" @close="editing = false"></edit-user>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.name="{ item }">
      <router-link :to="{ name: 'user-calendar', params: { userSlug: item.slug } }"
                   class="text-decoration-none black--text hover-underline">
        <span :class="{ 'grey--text': !item.active }">
          {{ item.name }}
        </span>
      </router-link>
      <v-chip v-if="item.admin" label small class="ml-3">Admin</v-chip>
    </template>
    <template v-slot:item.role="{ item }">
      <span :class="{ 'grey--text': !item.active }">
        {{ item.role | fullTitle }}
      </span>
    </template>
    <template v-slot:item.status="{ item }">
      <span :class="{ 'grey--text': !item.active }">
        {{ item.status | title }}
      </span>
    </template>
    <template v-slot:item.email="{ item }">
      <span :class="{ 'grey--text': !item.active }">
        <span v-if="item.email">{{ item.email }}</span>
        <i v-else class="grey--text">Not set</i>
      </span>
    </template>
    <template v-slot:item.edit="{ item }">
      <v-icon small class="mr-4" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:item.working="{ item }">
      <user-working :user="item" @update="update"></user-working>
    </template>
    <template v-slot:item.view="{ item }">
      <v-icon small class="mr-4"
              @click="$router.push({ name: 'user-calendar', params: { userSlug: item.slug } })">
        mdi-calendar
      </v-icon>
    </template>
  </v-data-table>
</page-wrapper>
</template>

<script>
import { mapState } from 'vuex'
import PageWrapper from '../components/ui/PageWrapper'
import EditUser from '../components/user/EditUser'
import UserWorking from '../components/user/UserWorking'

export default {
  name: 'Users',
  components: {
    UserWorking,
    PageWrapper,
    EditUser
  },
  data() {
    return {
      headers: [
        { text: 'Name' },
        { text: 'Role' },
        { text: 'Status' },
        { text: 'Email' },
        { text: 'Edit', sortable: false },
        { text: 'Working', sortable: false },
        { text: 'View', sortable: false }
      ],
      editing: false,
      onlyActive: false
    }
  },
  computed: {
    ...mapState(['users']),
    userList() {
      return this.onlyActive ? this.users.filter(u => u.active) : this.users
    }
  },
  methods: {
    editItem(item) {
      this.editing = true
      this.$nextTick(() => {
        this.$refs.editor.setID(item.id)
      })
    },
    resetEditor() {
      this.$nextTick(() => {
        const editor = this.$refs.editor
        // only need to reset if it's been filled before
        editor && editor.setID()
      })
    },
    update(force = true) {
      this.$store.dispatch('getUsers', force)
    },
    rowFilter(value, search, item) {
      console.log(value, search, item)
    }
  },
  created() {
    this.headers.forEach((col) => {
      if (!col.value) {
        col.value = col.text.toLowerCase()
      }
    })
  },
  mounted() {
    this.update(true)
  }
}
</script>

<style scoped lang="scss">

</style>
